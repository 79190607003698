<template>
  <Banner bg-image="yellow-bg.jpg" talk-img="challenge-thoughts.png">
    <template v-slot:title> Challenge your thoughts</template>
    <template v-slot:subtitle>
      There are many different types of thinking patterns we might use when we
      have a desire to die. It can help to write down your thoughts and feelings
      so you can understand and even interrupt these patterns.
    </template>
    <template v-slot:description>
      Here are some ways to understand or even interrupt these patterns:
    </template>
  </Banner>

  <!-- QUESTIONS SECTION -->
  <section class="pt-10 md:pt-14">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="mb-6 text-2xl font-bold md:text-3xl text-blue">
        Questions to ask yourself to help challenge your negative thoughts or
        self-talk
      </div>
      <p>
        If you wake up feeling worthless and having a strong desire to die,
        start by writing down your thoughts. Then, challenge your thoughts by
        asking yourself some questions:
      </p>
      <!-- Questions  -->
      <div class="p-8 mt-8 bg-blue-200">
        <div
          class="flex items-center border-b-2 border-white-100 last:border-b-0"
          v-for="question in questions"
          :key="question.id"
        >
          <div class="m-6 text-4xl font-bold text-blue">
            {{ question.id }}
          </div>
          <div class="">
            {{ question.text }}
          </div>
        </div>
      </div>
      <!-- Questions end -->
    </div>
  </section>
  <!-- RECORD SHEET -->
  <section class="p-8 my-8">
    <div class="max-w-6xl p-4 mx-auto bg-blue-100 rounded-md :px-6">
      <div class="items-center md:flex">
        <div class="w-52 safety-img md:pr-9">
          <img src="../../assets/images/record-sheet.png" />
        </div>
        <div class="s-div">
          <div class="mb-4 text-2xl font-bold text-blue">
            Thought record sheet
          </div>
          <p class="mb-5">
            Use this thought record sheet to help you try to balance every
            negative thought with a positive one.
          </p>
          <a
            href="/files/thought-journal.pdf"
            download
            class="font-bold text-green hover:text-gray"
          >
            Download
            <svg
              class="inline-block"
              xmlns="http://www.w3.org/2000/svg"
              width="10.922"
              height="28"
              viewBox="0 0 10.922 28"
            >
              <path
                id="Path_4072"
                data-name="Path 4072"
                d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                transform="translate(-831.098 -480.029)"
                fill="currentColor"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </section>

  <!-- JOURNAL -->
  <section class="p-8 my-8">
    <div class="max-w-6xl p-4 mx-auto bg-blue-100 rounded-md sm:px-6">
      <div class="items-center md:flex">
        <div class="w-52 safety-img md:pr-9">
          <img src="../../assets/images/name-your-emotions-yoga.png" />
        </div>
        <div class="s-div">
          <div class="mb-4 text-2xl font-bold text-blue">
            Guided feelings and thought journal
          </div>
          <p class="mb-5">
            Use this guided feelings and thought journal to work through your
            emotions, how you feel and what triggers you.
          </p>
          <a
            href="/files/thought-diary.pdf"
            download
            class="font-bold text-green hover:text-gray"
          >
            Download
            <svg
              class="inline-block"
              xmlns="http://www.w3.org/2000/svg"
              width="10.922"
              height="28"
              viewBox="0 0 10.922 28"
            >
              <path
                id="Path_4072"
                data-name="Path 4072"
                d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                transform="translate(-831.098 -480.029)"
                fill="currentColor"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </section>

  <FooterCard
    img="help-for-someone-else-braid.png"
    text="View Tools & resources"
    name="ToolsAndResources"
  >
    <template v-slot:header>Try another coping strategy</template>
    <template v-slot:content>
      Different coping strategies resonate with different people.
      <br />
      Discover more coping strategies and add to your toolbox.
    </template>
  </FooterCard>
</template>

<script>
import Banner from "@/components/Banner.vue";
import FooterCard from "@/components/FooterCard.vue";
export default {
  name: "ChallengeYourThoughts",
  data() {
    return {
      questions: [
        {
          id: 1,
          text: "What is the evidence for and against my thinking?",
        },
        {
          id: 2,
          text: "Am I jumping to negative conclusions?",
        },
        {
          id: 3,
          text: "What would I tell a friend if he/she/they/them had the same thought?",
        },
        {
          id: 4,
          text: "What would a friend say about my thought?",
        },
        {
          id: 5,
          text: "Are there any other ways that I could look at this situation?",
        },
        {
          id: 6,
          text: "If I were being positive, how would I view the situation?",
        },
        {
          id: 7,
          text: "What can I do that will help me solve the problem?",
        },
        {
          id: 8,
          text: "What is my worth and who values it? Look to friends, family, teammates and teachers as examples of people who value you and your contributions.",
        },
      ],
    };
  },

  components: {
    Banner,
    FooterCard,
  },
};
</script>

<style scoped>
.gradient-white {
  background: linear-gradient(to bottom, #f8f8f8, #fff);
}
.safety-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.s-div {
  width: calc(100% - 200px);
}
@media (max-width: 767px) {
  .safety-img {
    margin: 0 auto 25px;
  }
  .s-div {
    width: 100%;
  }
}
</style>
